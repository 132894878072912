// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-course-js": () => import("./../src/templates/course.js" /* webpackChunkName: "component---src-templates-course-js" */),
  "component---src-templates-unit-js": () => import("./../src/templates/unit.js" /* webpackChunkName: "component---src-templates-unit-js" */),
  "component---src-templates-lesson-js": () => import("./../src/templates/lesson.js" /* webpackChunkName: "component---src-templates-lesson-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-index-js": () => import("./../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-curriculum-index-js": () => import("./../src/pages/curriculum/index.js" /* webpackChunkName: "component---src-pages-curriculum-index-js" */),
  "component---src-pages-fishtank-plus-index-js": () => import("./../src/pages/fishtank-plus/index.js" /* webpackChunkName: "component---src-pages-fishtank-plus-index-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-login-js": () => import("./../src/pages/login.js" /* webpackChunkName: "component---src-pages-login-js" */),
  "component---src-pages-register-js": () => import("./../src/pages/register.js" /* webpackChunkName: "component---src-pages-register-js" */),
  "component---src-pages-teacher-tools-index-js": () => import("./../src/pages/teacher-tools/index.js" /* webpackChunkName: "component---src-pages-teacher-tools-index-js" */)
}

